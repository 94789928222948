<script setup>
import MonacoEditor from '@/components/MonacoEditor.vue'
import PythonCanvas from '@/components/PythonCanvas.vue'
import { ref } from 'vue'

const refPythonCanvas = ref(null)
const splitterModel = ref(520)

const code = ref('import turtle\n' +
  '\n' +
  't = turtle.Turtle()\n' +
  't.forward(100)\n' +
  '\n' +
  'print("Hello World")\n')

function onButtonClick () {
  refPythonCanvas.value.runPythonCode()
}

</script>

<template>
  <q-splitter v-model="splitterModel" unit="px" reverse class="full-height">
    <template v-slot:before>
      <MonacoEditor v-model="code" language="javascript" theme="vs-dark"/>
    </template>
    <template v-slot:after>
      <div class="q-pa-sm">
        <q-btn @click="onButtonClick" label="Run" flat icon="play_arrow"/>
        <PythonCanvas :code="code" ref="refPythonCanvas"/>
      </div>
    </template>
  </q-splitter>
</template>
