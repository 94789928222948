<script setup>
import { onMounted, nextTick, ref } from 'vue'

const value = defineModel()
let monaco = null
const refEditor = ref(null)

let monacoEditor = null

onMounted(() => {
  nextTick(function () {
    import('monaco-editor').then(monacoImported => {
      monaco = monacoImported
      const elementEditor = refEditor.value
      monacoEditor = monaco.editor.create(elementEditor, {
        value: value.value,
        language: 'python',
        minimap: { enabled: false }
      })

      // Handle editor changes
      monacoEditor.onDidChangeModelContent(event => {
        value.value = monacoEditor.getValue()
      })
    })
  })
})

</script>

<template>
  <div ref="refEditor" class="editor"></div>
</template>

<style>
.editor {
  width: 100%;
  height: 100%;
}
</style>
