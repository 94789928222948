<script setup>

const props = defineProps({
  code: String
})

const Sk = window.Sk

function outf (text) {
  const mypre = document.getElementById('output')
  mypre.innerHTML = mypre.innerHTML + text

  console.log(text)
}

function builtinRead (x) {
  if (Sk.builtinFiles === undefined || Sk.builtinFiles.files[x] === undefined) {
    // throw "File not found: '" + x + "'"
  }
  return Sk.builtinFiles.files[x]
}

// eslint-disable-next-line no-unused-vars
const runPythonCode = () => {
  const code = props.code

  const mypre = document.getElementById('output')
  mypre.innerHTML = ''
  Sk.pre = 'output'
  Sk.configure({ output: outf, read: builtinRead });

  (Sk.TurtleGraphics || (Sk.TurtleGraphics = {})).target = 'mycanvas'
  const myPromise = Sk.misceval.asyncToPromise(function () {
    return Sk.importMainWithBody('<stdin>', false, code, true)
  })
  myPromise.then(function (mod) {
    console.log('success')
  },
  function (err) {
    console.log(err.toString())
  })
}

defineExpose({
  runPythonCode
})
</script>

<template>
  <div>
    <!-- If you want turtle graphics include a canvas -->
    <div id="mycanvas" class="shadow-2"></div>
    <pre id="output" ></pre>
  </div>
</template>

<style>
#mycanvas {
  width: 500px;
  height: 500px; /* Adjust the size as needed */
}</style>
